<template>
  <main
    :class="[
      'fs-container',
      {
        'full-map': fullMap,
        'is-map-view': isMapView,
        'is-properties-view': !isMapView
      }
    ]"
  >
    <!-- Maps -->
    <section id="map-column" class="fs-inner-container map-column">
      <div class="fullwidth-loading-wrap" v-if="fullMap && spinnerLoading">
        <b-spinner label="Spinning" variant="primary"></b-spinner>
      </div>
      <!-- Map wrapper -->
      <div id="map-container">
        <!-- Backbtns -->
        <div class="backbtns-wrap">
          <b-button @click="backBtn" id="back-btn" v-if="showBackLink">
            <span class="hi-arrow-right icon ml-2"></span>
            <span>عودة</span>
          </b-button>
          <b-button
            v-if="showMyPropertiesBtn"
            @click="fullMap = false"
            id="back-btn"
          >
            <span class="hi-apartment icon ml-2"></span>
            <span>عقاراتي</span>
          </b-button>
          <b-button
            v-if="showMyFavoritesBtn"
            @click="fullMap = false"
            id="back-btn"
          >
            <span class="hi-bookmark2-o icon ml-2"></span>
            <span>مفضلتي</span>
          </b-button>
        </div>
        <!-- Map -->
        <div id="map">
          <gmap-map
            ref="mapRef"
            :center="mapCenter"
            :zoom="mapZoom"
            :options="options"
            style="width: 100%; height: 100%"
            @tilesloaded="onTilesLoaded"
            @zoom_changed="onZoomChanged"
            @dragend="onDraged"
          >
            <!-- Gmap info window -->
            <gmap-info-window
              :options="infoWindowOptions"
              :opened="infoWinOpen"
              :position="infoWindow.position"
              @closeclick="closeInfoWinOpen"
            >
              <!-- Router link go to specific id -->
              <router-link
                tag="div"
                :to="`/property/${infoWindow.id}`"
                class="map-window-info"
              >
                <!-- Info window -->
                <figure class="infoWindow-img-wrap">
                  <figcaption>
                    <span>{{ infoWindow.type }}</span>
                    <span v-if="infoWindow.status">
                      {{ infoWindow.status }}</span
                    >
                  </figcaption>
                  <img
                    class="infoWindow-img"
                    v-if="infoWindow.img"
                    :src="infoWindow.img"
                  />
                </figure>
                <!-- Info inner -->
                <div class="window-info-inner">
                  <h3 v-if="infoWindow.price">
                    {{ numberWithCommas(infoWindow.price) }}
                    <span class="currency">ريال</span>
                  </h3>
                  <h3 v-else>
                    <span class="currency not-limited-price">علي السوم </span>
                  </h3>
                  <p class="listing-address" v-if="infoWindow.propertyAddress">
                    <i class="hi-map-pin"></i>
                    <span>{{ infoWindow.propertyAddress.address }}</span>
                  </p>
                  <div class="windown-ifno-features">
                    <div v-if="infoWindow.space" class="featured-item">
                      <span class="icon hi-area"></span>
                      <span>{{ infoWindow.space }}</span>
                    </div>
                    <div v-if="infoWindow.numberOfRooms" class="featured-item">
                      <span class="icon hi-bed"></span>
                      <span>{{ infoWindow.numberOfRooms }}</span>
                    </div>
                    <div
                      v-if="infoWindow.numberOfBathRoom"
                      class="featured-item"
                    >
                      <span class="icon hi-bathroom"></span>
                      <span>{{ infoWindow.numberOfBathRoom }}</span>
                    </div>
                  </div>
                </div>
              </router-link>
            </gmap-info-window>
            <!--  Gmap custom -->
            <gmap-custom-marker
              v-for="(property, index) in propertiesFound"
              :key="`custom-marker-${index}`"
              :ref="`marker-${property._id}`"
              :id="`marker-${property._id}`"
              :class="[
                'map-marker',
                {
                  'is-active': propId == property._id && infoWinOpen == true,
                  'is-current': property._id == currentPropertyId
                }
              ]"
              :marker="{
                lat: property.location.coordinates[1],
                lng: property.location.coordinates[0]
              }"
            >
              <router-link
                tag="div"
                @mouseover.native="toggleInfoWindow(property, property._id)"
                @mouseout.native="closeInfoWinOpen"
                :to="{
                  name: 'property',
                  params: { id: property._id, images360: 0 }
                }"
                ref="noopener"
              >
                <div
                  v-if="currentZoom > 12"
                  :class="[
                    'map-marker-price',
                    { 'is-visited': isInVisitedProps(property._id) }
                  ]"
                >
                  <span class="price">{{
                    property.price
                      ? numberFormatter(property.price, 0)
                      : 'السعر غير محدد'
                  }}</span>
                  <span class="currency" v-show="property.price"> ريال</span>
                </div>
                <div
                  v-else
                  :class="[
                    'dot-map-marker',
                    { 'is-visited': isInVisitedProps(property._id) }
                  ]"
                ></div>
              </router-link>
            </gmap-custom-marker>
            <!-- <gmap-custom-marker
              ref="current-property-marker"
              id="current-property-marker"
              class="map-marker is-current"
              :marker="mapCenter"
            >
              <span class="dot-map-marker"> </span>
            </gmap-custom-marker> -->
          </gmap-map>
        </div>
        <!-- Fullwidth toggle btn -->
        <button
          id="fullwidth-btn"
          variant="light"
          :class="['custom-map-button', { 'is-active': fullMap }]"
          @click="fullMap = !fullMap"
        >
          <span class="hi-expand"></span>
        </button>
        <!-- Current Locatino btn -->
        <button
          class="custom-map-button"
          id="gps-btn"
          variant="light"
          @click="geolocate()"
        >
          <span class="hi-gps-fixed"></span>
        </button>
      </div>
    </section>
    <!-- Data -->
    <section
      :class="[
        'fs-inner-container properties-column',
        { 'full-width-mages': fullWidthImages }
      ]"
    >
      <div class="fs-content">
        <!-- Filter -->
        <Filters
          v-if="inSearchResults && !isOwnerProperitesView"
          @on-generate-urlParms="generateUrlParm"
        ></Filters>
        <!-- Has no Search Results Noti -->
        <div
          class="has-no-noti"
          v-if="
            !spinnerLoading &&
            numberOfRealEstates == 0 &&
            !fullMap &&
            isInfavoritesView == false &&
            isOwnerProperitesView == false
          "
        >
          <span class="hi-house-search icon"></span>
          <h4>عفواً، لا توجد عقارات في هذه المنطقه</h4>
          <span class="text">حاول البحث في موقع أو حي آخر</span>
        </div>
        <!-- Has no Favorites Noti -->
        <div
          class="has-no-noti"
          v-if="
            isInfavoritesView &&
            favorites &&
            favorites.length == 0 &&
            !spinnerLoading
          "
        >
          <span class="hi-bookmark2-o icon"></span>
          <h4>ستظهر عقاراتك المفضلة هنا</h4>
          <span class="text">لا توجد عقارت في مفضلتك في الوقت الحالي</span>
        </div>
        <!-- Has no Realestes in My Realestes Noti -->
        <div
          class="has-no-noti"
          v-if="
            !spinnerLoading &&
            isOwnerProperitesView &&
            propertiesFound.length == 0
          "
        >
          <span class="hi-apartment icon"></span>
          <h4>ستظهر قائمة عقاراتك هنا</h4>
          <span class="text">ليس لديك عقارات على هكتار حتى الآن</span>
          <div>
            <b-button
              variant="primary"
              @click="addNewProperty"
              class="button primary background header-add-property-btn m-0 mt-4"
              >اضف عقارك الأول الآن
            </b-button>
          </div>
        </div>

        <div
          v-show="!fullMap"
          class="listings-container-wrap"
          :class="{ 'results-view': pageId == 'default' }"
        >
          <!-- Listings Placeholder -->
          <div
            v-if="spinnerLoading"
            class="listings-container listings-container-placeholder row fs-listings-res grid-layout"
          >
            <content-placeholders
              :rounded="true"
              class="showing-results showing-results-placeholder"
            >
              <content-placeholders-text :lines="2" />
            </content-placeholders>

            <div
              class="listing-item item-placeholder"
              v-for="key in pageSize"
              :key="key"
            >
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <!-- <content-placeholders-heading/> -->
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </div>
          </div>
          <div v-else class="listings-container-inner content-column">
            <!-- Showing Results -->
            <div class="listings-title" v-if="numberOfRealEstatesToDisplay">
              <div class="inner" :class="{ 'advertiser-title': advertiser }">
                <div>
                  <h4 class="d-block mb-2 font-weight-bold">
                    <span v-if="pageId === 'default'"
                      >انت الان تبحث في حي
                    </span>
                    {{ listingsTitle }}
                  </h4>
                  <p class="showing-results d-inline-block">
                    <span class="num font-14">{{
                      numberOfRealEstatesToDisplay
                    }}</span>
                    <span v-if="pageId === 'default'">
                      عقار ومنزل متاح على هكتار في:</span
                    >
                    <span v-else-if="pageId === 'my-favorites'">
                      عقار في مفضلتك على هكتار</span
                    >
                    <span v-else-if="isOwner"> عقار لديك على هكتار</span>
                    <span v-else-if="advertiser">
                      عقار متاح على هكتار للمُعلن
                    </span>
                  </p>
                  <p class="d-inline-block" v-if="pageId === 'default'">
                    {{ listingsTitle }}
                  </p>
                  <h3 v-if="advertiser" class="advertiser-name d-block">
                    {{ ownerData.name }}
                  </h3>
                  <span v-if="advertiser">
                    {{ advertiserType }}
                  </span>
                </div>

                <div class="agent-contact">
                  <a
                    class="agent-link"
                    :href="`tel:${ownerData.phoneNumber}`"
                    v-if="advertiser"
                  >
                    <i class="hi-phone-wave font-14"></i>
                  </a>
                  <a
                    class="agent-link"
                    v-if="advertiser"
                    target="_blank"
                    :href="`https://api.whatsapp.com/send?phone=${ownerData.phoneNumber}&text=مرحباً ${ownerData.name}`"
                  >
                    <i class="hi-whatsapp"></i>
                  </a>

                  <span
                    class="agent-link"
                    v-if="ownerData"
                    v-b-modal.share-property-modal
                  >
                    <i class="hi-share"></i>
                  </span>
                </div>
              </div>
            </div>

            <!-- Listings -->
            <div id="listings-list" class="listings-container grid-layout">
              <listing-item
                v-for="property in propertiesFound"
                :key="property._id"
                :property="property"
                usedIn="properties"
                :isOwnerProperitesView="isOwnerProperitesView"
                :isInfavoritesView="isInfavoritesView"
                @on-delete-property="deleteThisProperty"
                @on-get-favorties="getMyFavorties"
              >
              </listing-item>
            </div>
          </div>
          <div
            class="fs-listings mb-2 mt-4"
            v-if="numberOfRealEstates > pageSize"
          >
            <!-- Pagination -->
            <div class="pagination-container margin-top-10 margin-bottom-45">
              <nav class="pagination">
                <b-pagination
                  v-model="pageNumber"
                  :total-rows="numberOfRealEstates"
                  :per-page="pageSize"
                  align="center"
                  aria-controls="listings-list"
                  @input="
                    isOwnerProperitesView
                      ? getOwnerProperties()
                      : generateUrlParm() || isInfavoritesView
                      ? getMyFavorties()
                      : generateUrlParm()
                  "
                  hide-goto-end-buttons
                >
                  <template slot="next-text"
                    ><i class="hi-angle-left"></i
                  ></template>
                </b-pagination>
              </nav>

              <p>
                {{ pagiFrom }} - {{ pagiTo }} من {{ numberOfRealEstates }} نتيجة
                بحث
              </p>
            </div>
          </div>
        </div>

        <share-property-modal
          v-if="ownerData"
          :shareData="ownerData"
        ></share-property-modal>
      </div>
    </section>
    <!-- Map toggle -->
    <div id="map-toggle-nav" v-if="!images360IsActive">
      <!-- Go back btn -->
      <button
        class="btn btn--white btn--shadow back-btn"
        id="gps-btn-2"
        variant="light"
        @click="backBtn"
      >
        <span class="hi-arrow-right icon ml-2"></span> عودة
      </button>
      <!-- Location -->
      <button
        class="btn btn--white btn--shadow gps-btn-2"
        id="gps-btn-2"
        variant="light"
        @click="geolocate"
      >
        <span class="hi-gps-fixed icon"></span>
      </button>
      <!--  -->
      <b-button
        @click="isMapView = !isMapView"
        :class="[
          'btn btn--white btn--shadow map-toggle-btn',
          {
            'map-is-active': isMapView,
            'properties-is-active': !isMapView
          }
        ]"
        variant="primary"
      >
        <span v-if="!isMapView">
          <span class="hi-map ml-2"></span>
          <span class="text">الخريطة</span>
        </span>
        <span v-else>
          <span class="hi-apartment ml-2"></span>
          <span class="text" v-if="isPropertyPage">تفاصيل العقار</span>
          <span class="text" v-else>قائمة العقارات</span>
        </span>
      </b-button>
    </div>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { realEstateService, userService } from '../../services'
import config from '@/config'
import _ from 'lodash'
import { EventBus } from '@/services/EventBus'
import { gmapApi } from 'vue2-google-maps'
// Components
import ListingItem from '@/components/property/ListingItem.vue'
import Filters from '@/components/property/Filters.vue'
import SharePropertyModal from '@/components/modals/SharePropertyModal.vue'
import GmapCustomMarker from '@/components/map/GmapCustomMarker.vue'
// Style
import '@/styles/property/pagination.scss'
import '@/styles/property/map.scss'

export default {
  layout: 'map',
  components: {
    GmapCustomMarker,
    ListingItem,
    Filters,
    SharePropertyModal
  },
  props: ['fullMap'],
  data() {
    return {
      isInfavoritesView: false,
      isOwnerProperitesView: false,
      ownerData: null,
      advertiser: false,
      map: null,
      firstLoad: true,
      options: {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 1,
        restriction: {
          latLngBounds: {
            north: 32.17075,
            south: 16.375256,
            west: 34.501529,
            east: 55.637296
          },
          strictBounds: false
        },
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      },
      pageNumber: 1,
      lat1: null,
      lat2: null,
      lng1: null,
      inSearchResults: false,
      currentZoom: null,
      activeFilters: {
        pageSize: 6,
        lat1: null,
        lat2: null,
        long1: null,
        long2: null
      },
      loading: false,
      infoWindow: {
        position: null,
        id: null,
        img: null,
        type: null,
        status: null,
        purpose: null,
        space: null,
        price: null,
        currency: null,
        numberOfRooms: null,
        numberOfBathRoom: null
      },
      infoWinOpen: false,
      infoWindowOptions: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      propId: null,
      currentPropertyId: null,
      fullWidthImages: false,
      images360IsActive: false,
      isMapView: false
    }
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        this.setPropertiesFound(0)
        this.setNumberOfRealEstates(0)
        if (id === 'my-favorites') {
          this.inSearchResults = false
          this.getMyFavorties()
          this.isInfavoritesView = true
          this.isOwnerProperitesView = false
          return
        }
        if (id === 'default') {
          this.inSearchResults = true
          this.isInfavoritesView = false
          this.isOwnerProperitesView = false
          return
        }
        this.isInfavoritesView = false
        this.isOwnerProperitesView = true
        this.getOwnerProperties()
      }
    }
  },
  computed: {
    google: gmapApi,
    ...mapState('accountState', ['user', 'status', 'favorites', 'configs']),
    ...mapState('userState', ['spinnerLoading']),
    ...mapState('realEstateState', [
      'propertiesFound',
      'numberOfRealEstates',
      'pageSize',
      'mapEventsFlag',
      'mapCenter',
      'mapZoom',
      'urlQuery',
      'visitedProps',
      'listingsTitle'
    ]),
    showBackLink() {
      return this.$route.params.id !== 'default'
    },
    showMyPropertiesBtn() {
      return this.fullMap === true && this.$route.params.id === this.user._id
    },
    showMyFavoritesBtn() {
      return this.fullMap === true && this.$route.params.id == 'my-favorites'
    },
    isPropertyPage() {
      return this.$route.name === 'property'
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    isLoggedIn() {
      return this.status.loggedIn
    },
    pagiFrom() {
      return this.pageNumber < 2 ? 1 : 1 + this.pageSize * (this.pageNumber - 1)
    },
    pagiTo() {
      return this.pageNumber * this.pageSize > this.numberOfRealEstates
        ? this.numberOfRealEstates
        : this.pageNumber * this.pageSize
    },
    numberOfRealEstatesToDisplay() {
      return this.numberOfRealEstates
    },
    pageId() {
      return this.$route.params.id
    },
    isOwner() {
      return this.user && this.user._id == this.pageId
    },
    advertiserType() {
      return (
        this.configs.userTypes?.find(
          (type) => type.code === this.ownerData?.userType
        )?.nameAr ?? ''
      )
    }
  },
  created() {
    if (this.mapZoom < 8) {
      this.$toasted.show(
        'الرجاء التقريب لمدينة محددة لعرض قائمة العقارات المتاحة بها',
        {
          duration: 0,
          type: 'success',
          icon: 'hi-close'
        }
      )
    }
    this.setMapCenterFromUrl()
    this.setMapEventsFlag(false)
    // this.setPropertiesFound([]);
    EventBus.$on('placeSelected', (place) => {
      if (this.map && place.types) {
        // Viewport Bounderies
        const viewportBounds = this.map.getBounds()
        let zoom = null
        if (place.types.includes('country')) {
          // all the country
          zoom = 5
        } else if (place.types.includes('locality')) {
          // city
          zoom = 10
        } else if (place.types.includes('route')) {
          // street
          zoom = 14
        } else if (place.types.includes('point_of_interest')) {
          // spcial place
          zoom = 16
        } else if (
          place.types.includes('neighborhood') ||
          place.types.includes(
            'sublocality_level_1' || place.types.includes('sublocality')
          )
        ) {
          // spcial place
          zoom = 14
        } else {
          const mapConainer = document.getElementById('map-container')
          const width = mapConainer.offsetWidth
          const height = mapConainer.offsetHeight
          zoom = this.getBoundsZoomLevel(viewportBounds, {
            width,
            height
          })
        }
        this.map.setZoom(zoom)
      }
    })
    EventBus.$on('on-toggle-infoWindow', (property, id) => {
      this.toggleInfoWindow(property, id)
    })
    EventBus.$on('on-close-infoWindow', () => {
      this.closeInfoWinOpen()
    })
  },
  mounted() {
    // sync pageNumber with url parameters
    let currentUrlObject = realEstateService.getCurrentUrlParamsObj()
    this.pageNumber = currentUrlObject.pageNumber
      ? currentUrlObject.pageNumber
      : 1
    EventBus.$on('onDragMap', () => (this.pageNumber = 1))
    this.$refs.mapRef.$mapPromise.then(() => {
      if (this.$route.name == 'properties')
        this.map = this.$refs.mapRef.$mapObject
    })
    // store.commit('realEstateState/realEstateStatus');
    this.inSearchResults = this.pageId === 'default' ? true : false
    if (!this.isLoggedIn && this.pageId === 'my-favorites') {
      this.$router.push({ name: 'login' })
    } else if (this.pageId === 'my-favorites') {
      this.setPropertiesFound(0)
      this.setNumberOfRealEstates(0)
      this.getMyFavorties()
    } else if (this.pageId != '' && this.pageId != 'default') {
      this.getOwnerProperties()
    } else {
      // fix browser backButton issue, when you on search results and
      // go to myProperties or MyFavorites then back to Search Results page.
      if (this.listingsTitle == 'عقاراتي' || this.listingsTitle == 'مفضلتي')
        this.setPropertiesTitle('قائمة العقارات')
    }
  },
  methods: {
    ...mapActions('realEstateState', ['getRealEstate', 'setUrlQuery']),
    ...mapActions('accountState', ['getFavorites']),
    ...mapMutations('accountState', ['setFavorites']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('realEstateState', [
      'setNumberOfRealEstates',
      'setPropertiesFound',
      'setPropertiesTitle',
      'setMapCenter',
      'setMapEventsFlag',
      'setMapZoom'
    ]),
    toggleInfoWindow(property, id) {
      const position = {
        lat: property.location.coordinates[1],
        lng: property.location.coordinates[0]
      }
      const markerPosition = { position }
      const marker = new window.google.maps.Marker(markerPosition)
      this.infoWindow.position = position
      this.infoWindow.id = property._id
      this.infoWindow.type = property.type.nameAr
      this.infoWindow.status = property.status ? property.status.nameAr : ''
      this.infoWindow.space = property.space
      this.infoWindow.address = property.propertyAddress.address
      this.infoWindow.img =
        property.images.length > 0 ? property.images[0].smallImage.path : ''
      this.infoWindow.price = property.price
      this.infoWindow.currency = 'ريال'
      this.infoWindow.numberOfRooms = property.numberOfRooms
      this.infoWindow.numberOfBathRoom = property.numberOfBathRoom
      this.infoWindowOptions.pixelOffset = this.getInfowindowOffset(marker)
      //check if its the same marker that was selected if yes toggle
      if (this.propId == id) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        //if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true
        this.propId = id
      }
    },
    closeInfoWinOpen() {
      this.infoWinOpen = false
    },
    getInfowindowOffset(marker) {
      if (this.map) {
        const center = this.getPixelFromLatLng(this.map.getCenter())
        const point = this.getPixelFromLatLng(marker.getPosition())
        let quadrant = ''
        let offset = ''
        quadrant += point.y > center.y ? 'b' : 't'
        quadrant += point.x < center.x ? 'l' : 'r'
        if (quadrant == 'tr') {
          offset = new window.google.maps.Size(-140, 245)
        } else if (quadrant == 'tl') {
          offset = new window.google.maps.Size(140, 245)
        } else if (quadrant == 'br') {
          offset = new window.google.maps.Size(-140, -45)
        } else if (quadrant == 'bl') {
          offset = new window.google.maps.Size(140, -45)
        }
        return offset
      }
    },
    getPixelFromLatLng(latLng) {
      const projection = this.map.getProjection()
      const point = projection.fromLatLngToPoint(latLng)
      return point
    },
    onDraged() {
      const bounds = this.map.getBounds()
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      if (
        this.$route.name == 'properties' &&
        this.$route.params.id == 'default'
      ) {
        // Begin Loading
        this.setSpinnerLoading(true)
        this.currentZoom = this.map.getZoom()
        // 1- Set parmas for generate query
        // to set lat, lng in url
        this.setMapCenterFromMap()
        this.activeFilters.lat1 = sw.lat()
        this.activeFilters.lat2 = ne.lat()
        this.activeFilters.lng1 = sw.lng()
        this.activeFilters.lng2 = sw.lng()
        this.activeFilters.lat3 = ne.lat()
        this.activeFilters.lat4 = sw.lat()
        this.activeFilters.lng3 = ne.lng()
        this.activeFilters.lng4 = ne.lng()
        // Set lat lng from map center
        // From state
        let { lat, lng } = this.mapCenter
        this.activeFilters.lat = lat
        this.activeFilters.lng = lng
        if (!this.firstLoad) {
          this.activeFilters.pageNumber = 1
          EventBus.$emit('onDragMap', '')
        }
        this.firstLoad = false
        // get pageSize From state
        this.activeFilters.pageSize = this.pageSize
        // 2- Generate query & Update url
        realEstateService.generateQuery(this.activeFilters).then((query) => {
          // 3- Get results
          this.getRealEstate(query)
          // save query to use in in back btn.
          this.setUrlQuery(query)
        })
        // 4- Set properties title depend on lat,lng form url
        // let{lat, lng}= realEstateService.getCurrentUrlParamsObj();
        this.convertLatlngToAddress({ lat: Number(lat), lng: Number(lng) })
      }
    },
    onTilesLoaded() {
      if (!this.mapEventsFlag) {
        this.onDraged()
        this.setMapEventsFlag(true)
      }
    },
    onZoomChanged(zoom) {
      this.setMapZoom(zoom)
      if (zoom < 8) {
        this.$toasted.clear()
        this.$toasted.show(
          'الرجاء التقريب لمدينة محددة لعرض قائمة العقارات المتاحة بها',
          {
            duration: 0,
            type: 'success',
            icon: 'hi-close'
          }
        )
      } else {
        this.$toasted.clear()
      }
      if (this.mapEventsFlag) {
        this.currentZoom = zoom
        this.onDraged()
      }
    },
    convertLatlngToAddress(location) {
      // Convert the latlang to formatted address
      if (this.map && location) {
        const geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ latLng: location }, (results) => {
          if (results === null) return
          const shotDist = results[0].address_components[2].short_name
          const longDist = results[0].address_components[4].short_name
          this.setPropertiesTitle(`${shotDist} / ${longDist}`)
        })
      }
    },
    backBtn() {
      this.$router.go(-1)
      setTimeout(() => {
        if (this.$route.params.id == 'default') this.onDraged()
      }, 100)
    },
    setMapCenterFromMap() {
      // Set map center in state
      const lat = this.map.center.lat()
      const lng = this.map.center.lng()
      this.setMapCenter({ lat, lng })
    },
    setMapCenterFromUrl() {
      const { lat, lng } = realEstateService.getCurrentUrlParamsObj()
      if (lat && lng) {
        const center = { lat: Number(lat), lng: Number(lng) }
        this.setMapCenter(center)
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.setMapCenter(center)
      })
    },
    setCurrentMarker(id) {
      this.currentPropertyId = id
    },
    setFullWidthImages(val) {
      if (val === 'close') {
        this.fullWidthImages = false
      } else {
        this.fullWidthImages = !this.fullWidthImages
      }
    },
    onOpenimages360() {
      this.images360IsActive = true
    },
    hasPurpose(type) {
      return realEstateService.hasPurpose(type)
    },
    numberFormatter(num, digits) {
      return realEstateService.numberFormatter(num, digits)
    },
    numberWithCommas(x) {
      return realEstateService.numberWithCommas(x)
    },
    isInVisitedProps(id) {
      return this.visitedProps.includes(id)
    },
    generateUrlParm: _.debounce(function (filters) {
      this.setSpinnerLoading(true)
      filters = {
        ...filters,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
      }
      realEstateService.generateQuery(filters).then((query) => {
        this.getRealEstate(query)
      })
    }, 500),
    deleteThisProperty(propertyId, comesFrom = 'default') {
      this.$dialog
        .confirm('هل أنت متأكد من حذف هذا العقار؟', {
          loader: true,
          okText: 'تأكيد الحذف',
          cancelText: 'إلغاء',
          customClass: 'delete-dialog'
        })
        .then((confirm) => {
          realEstateService.deleteRealEstate(propertyId).then(
            () => {
              confirm.close()
              this.getOwnerProperties()
              this.$toasted.show('تم حذف العقار بنجاح', {
                duration: 2000,
                icon: 'hi-check'
              })
              if (comesFrom == 'single') {
                this.$router.push({
                  name: 'properties',
                  params: { id: this.user._id }
                })
              }
            },
            () => {
              confirm.close()
              this.$toasted.show('يرجى المحاولة مرة اخري', {
                duration: 2000,
                type: 'error',
                icon: 'hi-close'
              })
            }
          )
        })
    },
    async getOwnerProperties() {
      try {
        const userId = this.$route.params.id
        this.setSpinnerLoading(true)
        this.favoritesView = false
        this.isOwnerProperitesView = true

        const query = `account=${userId}&pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`
        const results = await realEstateService.ownerRealEstates(query)
        this.setSpinnerLoading(false)
        const realEstates = results.data.realEstates
        this.setPropertiesFound(realEstates)
        if (realEstates.length) {
          this.setNumberOfRealEstates(results.data.count)
          this.ownerData = realEstates[0].account

          if (realEstates[0].account.name && this.user?._id !== userId) {
            this.advertiser = true
            this.setPropertiesTitle('')
            document.title =
              'عقارات ' + realEstates[0].account.name + ' على هكتار'
          }

          if (this.user) {
            if (this.user._id == userId) {
              this.setPropertiesTitle('عقاراتي')
              document.title = 'قائمة عقاراتي على هكتار'
            }
          }
        } else {
          this.setNumberOfRealEstates(0)
        }
      } catch {
        this.setSpinnerLoading(false)
        this.$router.push({ name: '404' })
      }
    },
    async getMyFavorties() {
      this.setSpinnerLoading(true)
      const favorites = await userService.getFavorites(
        this.pageSize,
        this.pageNumber
      )
      this.setSpinnerLoading(false)
      this.isInfavoritesView = true
      const myFavorites = favorites.data.fav
      this.setFavorites(myFavorites)
      this.setNumberOfRealEstates(favorites.data.realEstatesCount)
      // update the title
      this.setPropertiesTitle('مفضلتي')
      document.title = 'مفضلتي على هكتار'
      const properties = []
      myFavorites.forEach((fav) => properties.push(fav.realestate))
      this.setPropertiesFound(properties)
    },
    addNewProperty() {
      const isLoggedInFromStorage = localStorage.getItem('logged_in')
      if (!isLoggedInFromStorage) {
        this.$toasted.show('من فضلك قم بتسجيل الدخول أولاً', {
          duration: 4000
        })
        if (this.$route.name !== 'login') this.$router.push({ name: 'login' })
      } else if (this.user && this.user.userType?.nameEn === 'normal') {
        this.$toasted.show(
          'نوع المستخدم باحث عن عقار لا يمكنه إضافة عقارات، فضلاً قم بتغيبر نوع الحساب من إعدادات ملفك الشخصي',
          { duration: 4000 }
        )
      } else {
        if (this.$route.name !== 'addProperty')
          this.$router.push({ name: 'addProperty', params: { id: 'new' } })
      }
    },
    // Map
    getBoundsZoomLevel(bounds, mapDim) {
      const WORLD_DIM = { height: 256, width: 256 }
      const ZOOM_MAX = 21
      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()
      const latFraction =
        (this.latRad(ne.lat()) - this.latRad(sw.lat())) / Math.PI
      const lngDiff = ne.lng() - sw.lng()
      const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360
      const latZoom = this.zoom(mapDim.height, WORLD_DIM.height, latFraction)
      const lngZoom = this.zoom(mapDim.width, WORLD_DIM.width, lngFraction)
      return Math.min(latZoom, lngZoom, ZOOM_MAX)
    },
    latRad(lat) {
      const sin = Math.sin((lat * Math.PI) / 180)
      const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
    },
    zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setSpinnerLoading(true)
    // Begin Loading
    to.name !== 'property' && this.setMapZoom(12)
    next()
  },
  beforeDestroy() {
    this.setNumberOfRealEstates(0)
  }
}
</script>
