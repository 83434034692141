<template>
  <div id="search-filters" :class="{ 'collapse-is-opend': isCollapseOpend }">
    <ul class="filter-list" id="mobile-filter-btn">
      <li>
        <b-button
          v-b-toggle.mobile-filters
          variant="primary"
          class="btn btn--white btn--shadow btn-filter"
          id="toggle-mobile-filter"
        >
          <span class="btn-text"
            ><span class="hi-filter icon"></span>
            <span class="text">تصفية العقارات</span></span
          >
          <span class="hi-angle-down"></span>
        </b-button>
      </li>
    </ul>

    <b-collapse id="mobile-filters">
      <div class="d-flex justify-content-center align-items-center">
        <div class="ml-2">
          <input
            v-model="agentToggal"
            class="radio-btn"
            type="radio"
            name="radios"
            id="radio2"
            :value="true"
            checked
          />
          <label class="h6" for="radio2"> الجهات العقارية</label>
          <input
            v-model="agentToggal"
            class="radio-btn"
            type="radio"
            name="radios"
            id="radio1"
            :value="false"
          />
          <label class="h6" for="radio1">عقارات</label>
        </div>
        <ul class="filter-list">
          <!-- Property Status -->
          <li class="toggle-filter">
            <b-form-group class="m-0">
              <b-form-radio-group
                id="filter-status-group"
                v-model="filter.status"
                @change="applyFilters"
                class="d-flex"
                :options="statuses"
                value-field="_id"
                text-field="nameAr"
                buttons
                button-variant="light"
                name="radios-btn-default"
              ></b-form-radio-group>
            </b-form-group>
          </li>

          <!-- Property Type -->
          <li class="property-type toggle-filter ">
            <b-dropdown
              ref="dropdownHide3"
              :text="
                filter.type != 0
                  ? getConfigNameById('realEstateTypes', 'Ar', filter.type)
                  : 'نوع العقار'
              "
              :variant="`-white btn--shadow ${isActiveTypes}`"
              :right="isDesktop ? true : false"
              class="p-0 narrow-width"
            >
              <b-dropdown-form class="p-3">
                <b-form-group class="m-0">
                  <b-form-radio-group
                    v-model="filter.type"
                    size="md"
                    value-field="_id"
                    text-field="nameAr"
                    :options="realEstateTypes"
                    @change="applyFilters"
                    stacked
                    id="type"
                  ></b-form-radio-group>
                </b-form-group>
              </b-dropdown-form>
            </b-dropdown>
          </li>
        </ul>
        <ul class="filter-list toggle-filter">
          <li class="filter-sort">
            <b-dropdown
              ref="dropdownHide2"
              :variant="
                `-white btn--shadow btn-filter ${isActiveOthersOptions}`
              "
              class="p-0"
            >
              <b-dropdown-form class="p-0">
                <div class="filter-btns">
                  <b-form-group class="m-0" label="عدد الغرف">
                    <b-form-radio-group
                      v-model="dropdownFilter.numberOfRooms"
                      class="d-flex"
                      :options="[1, 2, 3, 4, '+5']"
                      buttons
                      button-variant="light"
                      name="radios-btn-default"
                    ></b-form-radio-group>
                  </b-form-group>

                  <b-form-group class="m-0 mt-3" label="عدد الصالات">
                    <b-form-radio-group
                      v-model="dropdownFilter.numberOfLivingRoom"
                      class="d-flex"
                      :options="[1, 2, 3, '+4']"
                      buttons
                      button-variant="light"
                      name="radios-btn-default"
                    ></b-form-radio-group>
                  </b-form-group>

                  <b-form-group class="m-0 mt-3" label="عدد دورات المياة">
                    <b-form-radio-group
                      v-model="dropdownFilter.numberOfBathRoom"
                      class="d-flex"
                      :options="[1, 2, 3, '+4']"
                      buttons
                      button-variant="light"
                      name="radios-btn-default"
                    ></b-form-radio-group>
                  </b-form-group>
                </div>

                <div class="mt-4 min-max-fields">
                  <b-form-group label="المساحة">
                    <b-row>
                      <b-col class="pl-0">
                        <b-form-input
                          class="border-radius-left-0"
                          v-model="dropdownFilter.minSpace"
                          size="sm"
                          type="number"
                          placeholder="الحد الأدنى"
                        ></b-form-input>
                      </b-col>
                      <b-col class="pr-0">
                        <b-form-input
                          class="border-right-0"
                          v-model="dropdownFilter.maxSpace"
                          size="sm"
                          type="number"
                          placeholder="الحد الأقصى"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="min-max-fields">
                  <b-form-group label="السعر">
                    <b-row>
                      <b-col class="pl-0">
                        <b-form-input
                          class="border-radius-left-0"
                          v-model="dropdownFilter.minPrice"
                          size="sm"
                          type="number"
                          placeholder="الحد الأدنى"
                        ></b-form-input>
                      </b-col>
                      <b-col class="pr-0">
                        <b-form-input
                          class="border-right-0"
                          v-model="dropdownFilter.maxPrice"
                          size="sm"
                          type="number"
                          placeholder="الحد الأقصى"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="my-2 mx-auto apply-filter-btn">
                  <b-button
                    :disabled="!isDropdownFilterChanged"
                    @click="applyFilters"
                    class="text-center w-100"
                    >تطبيق</b-button
                  >
                </div>
              </b-dropdown-form>
            </b-dropdown>
          </li>

          <li class="filter-item">
            <b-dropdown
              ref="dropdownHide1"
              class="narrow-width"
              :variant="`-white btn--shadow btn-sort ${isActiveSort}`"
            >
              <b-dropdown-form class="p-2">
                <b-form-group>
                  <b-form-radio-group
                    v-model="filter.sort"
                    @change="applyFilters"
                    size="lg"
                    :options="sortList"
                    stacked
                  ></b-form-radio-group>
                </b-form-group>
              </b-dropdown-form>
            </b-dropdown>
          </li>
          <li v-if="isActiveOthersOptions === 'active'" class="filter-item">
            <b-button
              id="clear-filter"
              class="px-2 font-weight-bolder clear-filter"
              variant="outline-primary"
              @click.prevent="resetFilter"
            >
            </b-button>
          </li>
        </ul>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import _ from 'lodash'
import { realEstateService } from '../../services'
// Style
import '../../styles/property/filter.scss'

export default {
  layout: 'map',
  data() {
    return {
      isFiltersActive: false,
      filter: {
        status: 0,
        type: 0,
        keywords: [],
        keywordsList: [],
        sort: null,
        dateAdded: null
      },
      dropdownFilter: {
        minPrice: null,
        maxPrice: null,
        minSpace: null,
        maxSpace: null,
        numberOfRooms: null,
        numberOfLivingRoom: null,
        numberOfBathRoom: null
      },
      filterHasValue: false,
      sortList: [
        { text: 'من الأحدث للأقدم', value: 'new' },
        { text: 'من الأقدم للأحدث', value: 'old' },
        { text: 'السعر من أعلى لأقل', value: 'priceHiLo' },
        { text: 'السعر من أقل لأعلى', value: 'priceLoHi' }
      ],
      stikyKit: {
        options: {
          parent: '.fs-content',
          offset_top: 88
        }
      },
      isActiveBtn: false,
      isCollapseOpend: false,
      agentToggal: false,
      isDropdownFilterChanged: false
    }
  },
  watch: {
    filter: {
      async handler(filters) {
        // if(this.$route.params.id != "default"){
        //     await this.$router.push({name: 'properties', params: {id: 'default'}});
        //     EventBus.$emit('ChangeToDefault', '');
        // }
        this.$emit('on-generate-urlParms', filters)
      }
      // deep: true
    },
    agentToggal(val) {
      this.setAgentLocator(val)
      val
        ? this.$router.push({
            name: 'agents',
            query: Object.assign(this.$route.query)
          })
        : ''
    },
    dropdownFilter: {
      handler() {
        this.isDropdownFilterChanged = true
      },
      deep: true
    }
  },
  computed: {
    ...mapState('accountState', [
      'user',
      'status',
      'configs',
      'favorites',
      'favoritesIds',
      'realEstateTypes',
      'realEstateStatus'
    ]),
    ...mapState('userState', ['spinnerLoading']),
    ...mapGetters('accountState', ['getConfigNameById']),
    ...mapState('agentLocator', ['agentLocator']),
    isLoggedIn() {
      return this.status.loggedIn
    },
    statuses() {
      let statuses = [{ nameAr: 'الكل', _id: 0 }]
      this.realEstateStatus.forEach(status => {
        if (['rent', 'sale'].includes(status.nameEn)) {
          statuses.push(status)
        }
      })
      return statuses
    },
    isActiveTypes() {
      // return this.filter.types.length ? 'active' : '';
      return this.filter.type !== '' && this.filter.type != 0 ? 'active' : ''
    },
    isActiveOthersOptions() {
      return this.dropdownFilter.numberOfRooms ||
        this.dropdownFilter.numberOfBathRoom ||
        this.dropdownFilter.numberOfLivingRoom ||
        this.dropdownFilter.minPrice ||
        this.dropdownFilter.maxPrice ||
        this.dropdownFilter.minSpace ||
        this.dropdownFilter.maxSpace ||
        this.filter.type ||
        this.filter.status
        ? 'active'
        : ''
    },
    isActiveSort() {
      return this.filter.sort == 'priceHiLo' || this.filter.sort == 'priceLoHi'
        ? 'active'
        : ''
    },
    isDesktop() {
      return this.$mq === 'desktop'
    }
  },
  created() {
    // debugger;
    this.setFiltersFromUrl()
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (isJustShown === true) {
        this.isCollapseOpend = true
      } else {
        this.isCollapseOpend = false
      }
    })
  },
  methods: {
    ...mapMutations('agentLocator', ['setAgentLocator']),
    applyFilters() {
      this.$emit('on-generate-urlParms', {
        ...this.filter,
        ...this.dropdownFilter
      })
      this.isDropdownFilterChanged = false
      this.filterHasValue = true
      this.$refs.dropdownHide1.hide()
      this.$refs.dropdownHide2.hide()
      this.$refs.dropdownHide3.hide()
    },
    backToresults() {
      this.$router.push({ name: 'properties', params: { id: 'default' } })
    },
    setFiltersFromUrl() {
      let that = this
      let currentUrlObject = realEstateService.getCurrentUrlParamsObj()
      // let {maxPrice, minPrice, minSpace, maxSpace, status} = currentUrlObject
      _.map(currentUrlObject, function(value, key) {
        if (value && key in that.filter) {
          that.filter[key] = value
        }
      })
    },
    resetFilter() {
      this.dropdownFilter.numberOfRooms = null
      this.dropdownFilter.numberOfBathRoom = null
      this.dropdownFilter.numberOfLivingRoom = null
      this.dropdownFilter.minPrice = null
      this.dropdownFilter.maxPrice = null
      this.dropdownFilter.minSpace = null
      this.dropdownFilter.maxSpace = null
      this.filter.sort = null
      this.filter.status = null
      this.filter.type = ''
      this.applyFilters()
      this.filterHasValue = false
      this.$nextTick(() => {
        this.isDropdownFilterChanged = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.clear-filter{
  &:hover {
  background-color: white;
  color: #342f29;
}
&::after {
  font-family: 'hectar';
  content: '\f2d7';
  font-size: 16px;
  color: #342f29;
}
}

.radio-btn {
  appearance: none;
  display: none;
  height: 0;
  width: 0;

  & + label {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: -5px;
    padding: 10px 16px;
    color: #3d3d3d;
    text-align: center;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 12px !important;
    
  }

  &:checked + label {
    background-color: #3dba7e;
    color: white;
    text-align: center;
    border: 1px solid #e8e8e8;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 12px !important;
  }
}
.apply-filter-btn {
  width: 90%;
  button {
    background-color: #3dba7e !important;
  }
}
</style>
